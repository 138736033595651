export const hexToAscii = (value: string): string => {
    let str = '';
    for (let i = 0; i < value.length; i += 2)
        str += String.fromCharCode(parseInt(value.substr(i, 2), 16));
    return str;
}

export const asciiToHex = (value: string) => {
    const arr1 = [];
    for (let n = 0, l = value.length; n < l; n++) {
        const hex = Number(value.charCodeAt(n)).toString(16);
        arr1.push(hex);
    }
    return arr1.join('');
}

/**
 *
 * @param oldId
 * @param newId
 * @param writeSuccessCallback
 * @param writeErrorCallback
 */
export const writeRFID = (oldId: string, newId: string, writeSuccessCallback: (message: string) => void, writeErrorCallback: (message: string) => void) => {
    // The hex-value has to be 24 digits
    const updatedId = newId.padEnd(24, "0");

    if (window.cordova) {
        const rfid = window.cordova.plugins.RFID;
        rfid.writeId(writeSuccessCallback, writeErrorCallback, oldId, updatedId);
    } else {
        writeErrorCallback("cordova not found");
    }
}
