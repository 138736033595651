import {Box, Button} from "@mui/material";
import {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {generatePath} from "react-router";
import {MafiInformation, RoTrailMafiService} from "@blg/blg-core";
import {ScanMafiNumberComponent, SnackbarUtil} from "@blg/blg-core/lib/esm/components";
import {ROUTES} from "../../routes/routes";
import {setActiveView, setShowLoading} from "../../store/slices/global";
import {store} from "../../store/store";
import {defaultButtonProps, defaultButtonStyle} from "../../styles/defaultStyles";

const StackView = () => {
    const theme = useTheme();
    const {t} = useTranslation();
    const navigate = useNavigate();

    /**
     * the current mafiNr
     */
    const [mafiNr, setMafiNr] = useState<string>('');

    /**
     * resetting the app bar title when the user enters the task selection view
     */
    useEffect(() => {
        // store.dispatch(setActiveTutorial({tutorial: "STACK", show: true}));
        store.dispatch(setActiveView({text: t('STACK.OPTIONS.STACK'), params: null}));
    }, []);

    /**
     * is called when the user pressed the search button, searches for a mafi with the corresponding
     * number,
     */
    const onSearchPressed = async (mafiSearch: string) => {
        try {
            store.dispatch(setShowLoading(true));
            const response = await RoTrailMafiService.instance.getByMafiNo(mafiSearch);
            if (response) {
                const mafi = MafiInformation.parseFromObject(response.data);
                setMafiNr(mafi.mafiNo);
                const path = generatePath(ROUTES.STACK_DETAILS, {'mafiNo': mafi.mafiNo});
                navigate(path);
            }
        } catch (e) {
            SnackbarUtil.error(t('SCAN_MAFI.NO_MAFI'));
        } finally {
            store.dispatch(setShowLoading(false));
        }
    }

    return <Box sx={{
        height: "100%", display: "flex", alignItems: "center", flexDirection: "column",
        backgroundColor: theme.palette.blueLight.main
    }}>
        <Box sx={{p: 1.5, display: "flex", flexDirection: "column", width: "100%"}}>
            <ScanMafiNumberComponent mafiNr={mafiNr}
                                     selectTagEvent={(tag, isInput) => {
                                         setMafiNr(tag.parsedId);
                                         if (!isInput) {
                                             onSearchPressed(tag.parsedId);
                                         }
                                     }}/>
            <Button
                disabled={mafiNr.length <= 0}
                {...defaultButtonProps}
                sx={{...defaultButtonStyle, mt: 2}}
                onClick={() => onSearchPressed(mafiNr)}
            >
                {t('GENERAL.SEARCH_MAFI')}
            </Button>
        </Box>
    </Box>
}

export default StackView;
