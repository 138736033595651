import {combineReducers} from "redux";
import storage from 'redux-persist/lib/storage'
import global from './global';
import auth from './auth';
import custom from './custom';

export const rootReducer = combineReducers({
    global, auth, custom
});

export default (state: any, action: any) => {
    if (action.type === "CLEAR_STORE") {
        storage.removeItem(`persist:${process.env.REACT_APP_STORE_KEY}`);
        return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
}
