import {Parseable} from "@blg/blg-core";
import {MafiProperties} from "./MafiProperties";
import {MafiLocation} from "./MafiLocation";
import {GoodsData} from "./GoodsDataInterface";

export default class MafiInformation extends Parseable {
	mafiNo!: string;
	typeCode!: string;
	categoryCode!: string;
	length!: number;
	width!: number;
	weightMaxLoad!: number;
	tara!: number;
	extendedWidth!: number;
	lashingMethod!: string;
	id!: number;
	gooseneck!: boolean;
	uvvExpireDate?: Date;
	remark!: string;
	minimalData!: boolean;
	weightNet!: number;
	weightTotal!: number;
	stackRowNo!: number;
	stackNo!: string;
	mafiOwnerCode!: string;
	loadStatus?: 'VOLL' | 'LEER';
	destinationCode!: string;
	entryDate?: Date;
	entryVoyageNo!: string;
	entryTmName!: string;
	exitVoyageNo!: string;
	exitTmName!: string;
	goodsData!: GoodsData[] | MafiInformation[];
	mafiProperties!: MafiProperties;
	mafiLocation!: MafiLocation;
    mafiStatus!: number;
    brOfficeId?: number;

	public static parseFromObject(object: any): MafiInformation {
		const mafi = new MafiInformation();
		Object.assign(mafi, object);

		return mafi;
	}
}
