import React from "react";
import {UnstackDetailComponent} from '@blg/blg-core'
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../routes/routes";
import {useAppSelector} from "../../store/hooks";
import {setShowLoading} from "../../store/slices/global";
import {store} from "../../store/store";

const UnStackDetailView = () => {
    const navigate = useNavigate();
    const launcherParams = useAppSelector(state => state.global).launcherParams;

    return <UnstackDetailComponent
        onSaveClicked={() => {
            // In the electron app -> close the stack app
            if (launcherParams && launcherParams.isTug) {
                window.api.closeApp();
            } else {
                navigate(ROUTES.UNSTACK);
            }
        }}
        navigateToRoute={() => {
            navigate(ROUTES.HOME);
        }}
        setShowLoading={(loading) => store.dispatch(setShowLoading(loading))}/>
}

export default UnStackDetailView;
