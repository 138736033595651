import {createSlice, PayloadAction} from "@reduxjs/toolkit"

export type Tutorial = "LOGIN" | "STACK" | "NEW_STACK" | "ADD_MAFI" | "UNSTACK" | "UNSTACK_DETAILS"

export interface GlobalState {
    showLoading: boolean,
    loadingText: string | undefined,
    navigationAction: any,
    activeView: {
        text: string, params: any
    },
    activeTutorial: { tutorial: Tutorial, show: boolean },
    isOnline: boolean,
    scannerConnection: boolean,
    launcherParams?: {
        isTug: boolean,
        isLauncher: boolean,
        path?: string
    },
}

const initialState: GlobalState = {
    showLoading: false,
    loadingText: undefined,
    activeView: {text: "", params: null},
    navigationAction: null,
    isOnline: true,
    scannerConnection: false,
    activeTutorial: {tutorial: "LOGIN", show: true}
};

const globalState = createSlice({
    name: "global",
    initialState,
    reducers: {
        setShowLoading(state: GlobalState, action: PayloadAction<boolean>) {
            state.showLoading = action.payload;
        },
        setShowLoadingText(state: GlobalState, action: PayloadAction<string | undefined>) {
            state.loadingText = action.payload;
        },
        setActiveView(state: GlobalState, action: PayloadAction<{
            text: string, params: any
        }>) {
            state.activeView = action.payload;
        },
        setNavigationAction(state: GlobalState, action: PayloadAction<any>) {
            state.navigationAction = action.payload;
        },
        setIsOnline(state: GlobalState, action: PayloadAction<boolean>) {
            state.isOnline = action.payload;
        },
        setLauncherParams(state: GlobalState, action: PayloadAction<{ isLauncher: boolean, isTug: boolean, path?: string }>) {
            state.launcherParams = action.payload
        },
        setActiveTutorial(state: GlobalState, action: PayloadAction<{ show: boolean, tutorial: Tutorial }>) {
            state.activeTutorial = action.payload;
        },
        setScannerConnection(state: GlobalState, action: PayloadAction<boolean>) {
            state.scannerConnection = action.payload;
        },
    },
});

export const {
    setShowLoading,
    setActiveView,
    setIsOnline,
    setShowLoadingText,
    setScannerConnection,
    setNavigationAction,
    setLauncherParams,
    setActiveTutorial
} = globalState.actions;
export default globalState.reducer;
