import React from 'react';
import {Box} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {Outlet} from "react-router-dom";
import {setToken} from 'store/slices/auth';
import { setLauncherParams } from 'store/slices/global';
import HandleAuthComponent from "../components/handleAuthComponent";
import {store} from "../store/store";

function DefaultView() {
    const theme = useTheme();

    if (window.api) {
        window.api.receiveDeeplink((data: any) => {
            console.log('received data', data);
            if (data.queryParams) {
                const fixedQueryData = data.queryParams.replace(/"/g, "");
                const queryData = new URLSearchParams(fixedQueryData);
                const parsedData = Object.fromEntries(queryData.entries());
                console.log("parsed", parsedData);

                const launcherParams = {
                    isLauncher: parsedData.launcher === 'true',
                    isTug: parsedData.tugApp === 'true',
                    path: parsedData.path
                }
                store.dispatch(setLauncherParams(launcherParams));

                if (parsedData.token) {
                    store.dispatch(setToken(parsedData.token));
                }
            }
        });
    }

    return (
        <Box sx={{
            backgroundColor: theme.palette.primary.main, height: "100vh", width: "100%",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
        }}>
            <HandleAuthComponent />
            <Outlet/>
        </Box>
    );
}

export default DefaultView;
