import React, {useEffect, useRef, useState} from 'react';
import {Backdrop, Box} from '@mui/material';
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import LoadingImage from "../assets/images/loading-animation.svg";
import {ROUTES} from "../routes/routes";
import {useAppSelector} from "../store/hooks";
import {setRefreshToken} from "../store/slices/auth";
import {store} from "../store/store";

const HandleAuthComponent = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const queryRefreshToken = searchParams.get('refreshToken');

    const timerId = useRef<NodeJS.Timeout | null>(null);

    const token = useAppSelector(state => state.auth).token;
    const refreshToken = useAppSelector(state => state.auth).refreshToken;
    const launcherParams = useAppSelector(state => state.global).launcherParams;
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);


    useEffect(() => {
        setShowLoadingAnimation(true);
        return () => {
            setShowLoadingAnimation(false);
            if (timerId.current) {
                clearTimeout(timerId.current);
            }
        }
    }, []);

    useEffect(() => {
        if (queryRefreshToken) {
            store.dispatch(setRefreshToken(queryRefreshToken));
        }
    }, [queryRefreshToken]);

    useEffect(() => {
        if (token || refreshToken) {
            if (timerId.current) {
                clearTimeout(timerId.current);
                timerId.current = null;
            }
            timerId.current = setTimeout(() => {
                setShowLoadingAnimation(false);

                // The app was opened by another app or the launcher
                if (launcherParams && launcherParams.path) {
                    navigate(launcherParams.path);
                    return;
                }

                if (location.pathname === "/login") {
                    return;
                }

                if (location.pathname === "/") {
                    navigate(ROUTES.HOME);
                } else {
                    navigate(location.pathname);
                }
            }, 500);
        } else {
            timerId.current = setTimeout(() => {
                setShowLoadingAnimation(false);
                navigate(ROUTES.LOGIN);
            }, 1500);

        }
    }, [token, refreshToken, launcherParams?.path]);

    return (<Box>
        <Box sx={{position: "absolute", top: 0, left: 0}}>
            <Backdrop
                sx={{
                    backgroundColor: "rgba(222,224,233,0.9)",
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={showLoadingAnimation}
            >
                <object type="image/svg+xml" style={{width: 275, height: 275}} data={LoadingImage}>svg-animation
                </object>
            </Backdrop>
        </Box>
    </Box>);
}

export default HandleAuthComponent;
