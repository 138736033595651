import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles/global.scss";
import "./i18n";
import App from './App';
import reportWebVitals from "./reportWebVitals";

import 'react-medium-image-zoom/dist/styles.css'

const startApp = () => {
    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );

    reportWebVitals();
}

// @ts-ignore
if (!window['cordova']) {
    startApp()
} else {
    console.log('wait for cordova');
    document.addEventListener('deviceready', startApp, false)
}

