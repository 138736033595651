import HelpIcon from "@mui/icons-material/Help";
import {useTheme} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {AppBar, Box, Grid, IconButton, Toolbar, Typography} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from "@mui/icons-material/Close";

import {useAppSelector} from "../../store/hooks";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../routes/routes";
import {useLocation} from 'react-router-dom';
import {store} from "../../store/store";
import { BuildInfoDialogComponent, Emitter, EmitterEvents } from "@blg/blg-core";
import TutorialDialogComponent from "components/dialog/tutorialDialog.component";

const NavigationComponent = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const activeView = useAppSelector(state => state.global).activeView;
    const username = useAppSelector(state => state.auth).username;
    const [showBackButton, setShowBackButton] = useState(false);
    const [showBuildInfo, setShowBuildInfo] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false);
    const navigationAction = useAppSelector(state => state.global).navigationAction;
    const activeTutorial = useAppSelector(state => state.global).activeTutorial;
    const launcherParams = useAppSelector(state => state.global).launcherParams;
    const location = useLocation();

    useEffect(() => {
        setShowBackButton(location.pathname !== ROUTES.HOME);
    }, [location]);

    const closeAppButton = () => {
        if ((!showBackButton && launcherParams && launcherParams.isLauncher) || (launcherParams && launcherParams.isTug)) {
            return <IconButton
                size="large"
                edge="start"
                onClick={() => {
                    if (launcherParams && launcherParams.isTug) {
                        window.api.closeApp();
                    }

                    Emitter.emit(EmitterEvents.DISCONNECT_RFID, null);
                }}
            >
                <CloseIcon sx={{color: '#FFF'}}/>
            </IconButton>
        }
    }

    return (
        <Box>
            <AppBar position="static">
                <Toolbar sx={{display: "flex", justifyContent: "center", alignItems: 'center'}}>
                    <Grid container wrap={"nowrap"}>
                        <Grid item xs={3}>
                            {showBackButton && (!launcherParams || !launcherParams.isTug) && <Box sx={{display: "flex"}}>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    onClick={() => {
                                        if (launcherParams && launcherParams.isTug) {
                                            window.api.closeApp();
                                        } else {
                                            navigate(-1);
                                        }
                                    }}>
                                    <ArrowBackIcon sx={{color: theme.palette.white.main}}/>
                                </IconButton>
                            </Box>}
                            { closeAppButton() }
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{
                                display: 'flex',
                                height: '100%',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Typography sx={{color: theme.palette.white.main, textAlign: "center", fontSize: '16px'}}>
                                    { /* @ts-ignore */  }
                                    {t(activeView.text, activeView.params)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3} sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            {navigationAction}

                            {!navigationAction && <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                                { activeTutorial.show &&
                                    <Box onClick={() => setShowTutorial(true)}
                                         sx={{
                                             display: "flex", flexDirection: "column", alignItems: "center",
                                             mr: 2, cursor: "pointer"
                                         }}>
                                        <HelpIcon sx={{color: '#FFF'}}/>
                                        <Typography variant={"caption"} sx={{
                                            color: '#FFF',
                                            mt: 0.5,
                                            textAlign: "center",
                                            lineHeight: "1.3"
                                        }}>
                                            Hilfe
                                        </Typography>
                                    </Box>
                                }
                                <Box onClick={() => setShowBuildInfo(true)}
                                     sx={{
                                         display: "flex", flexDirection: "column", alignItems: "center",
                                         mr: 2, cursor: "pointer"
                                     }}>
                                    <InfoIcon sx={{color: theme.palette.white.main}}/>
                                    <Typography variant={"caption"} sx={{color: theme.palette.white.main, mt: 0.5, textAlign: "center", lineHeight: "1.3"}}>
                                        {process.env.REACT_APP_VERSION}
                                    </Typography>
                                </Box>
                                {/*/!* logout button, is called when the user is logged out, clears the store and goes back to the login screen *!/*/}
                                <IconButton
                                    onClick={() => {
                                        store.dispatch({type: "CLEAR_STORE"});
                                        navigate(ROUTES.ROOT, {replace: true});

                                        if (launcherParams && launcherParams.isLauncher) {
                                            if (navigator.app) {
                                                navigator.app.exitApp();
                                            } else if (navigator.device) {
                                                navigator.device.exitApp();
                                            } else {
                                                window.close();
                                            }
                                        } else {
                                            navigate(ROUTES.LOGIN);
                                        }

                                    }}
                                    color="inherit">
                                    <LogoutIcon />
                                </IconButton>
                            </Box>}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <BuildInfoDialogComponent showDialog={showBuildInfo} username={username!}
                                      closeEvent={() => setShowBuildInfo(false)}/>
            <TutorialDialogComponent showDialog={showTutorial} closeEvent={() => setShowTutorial(false)}/>
        </Box>
    )
}

export default NavigationComponent;
