import {Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../routes/routes";
import {useTheme} from "@mui/material/styles";

import EntStack from '../assets/images/EntStack.png';
import Stack from '../assets/images/Stack.png';
import {defaultButtonProps, defaultButtonStyle} from "../styles/defaultStyles";
import {useEffect} from "react";
import {store} from "../store/store";
import {setActiveTutorial, setNavigationAction} from "../store/slices/global";

const StackOptionsView = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        store.dispatch(setNavigationAction(null));
        store.dispatch(setActiveTutorial({tutorial: "LOGIN", show: false}));
    }, []);

    return <Box sx={{
        height: "100%", display: "flex", flexDirection: "column", p: 2,
        backgroundColor: theme.palette.surface.main
    }}>
        <Box sx={{
            height: "100%", display: "flex", flexDirection: "column",
            backgroundColor: theme.palette.surface.main
        }}>
            <Typography sx={{color: theme.palette.primary.main, fontSize: '22px', fontWeight: '600', mb: 2}}>
                Aufgabe auswählen
            </Typography>

            <Button
                onClick={() => navigate(ROUTES.STACK)}
                {...defaultButtonProps}
                sx={{
                    ...defaultButtonStyle, flexDirection: 'column', width: '100%', p: 5, height: '114px',
                    "&:hover": {backgroundColor: theme.palette.primary.light},
                }}>
                <img src={Stack} width={40} alt={"stack"} style={{ border: "1px solid #fff", borderRadius: "50%" }}/>
                <Typography variant="h6" sx={{ mt: 1, color: theme.palette.white.main }}>Stacken</Typography>
            </Button>

            <Button
                onClick={() => navigate(ROUTES.UNSTACK)}
                {...defaultButtonProps}
                sx={{
                    ...defaultButtonStyle,
                    backgroundColor: theme.palette.white.main,
                    height: '114px',
                    mt: 2,
                    width: '100%',
                    flexDirection: 'column',
                    "&:hover": {backgroundColor: theme.palette.primary.main},
                    "&:hover h6": {color: theme.palette.white.main}
                }}>
                <img src={EntStack} width={40} alt={"unstack"}/>
                <Typography variant="h6" sx={{ mt: 1 }}>Entstacken</Typography>
            </Button>
        </Box>
    </Box>;
}

export default StackOptionsView;
