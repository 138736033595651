import {Parseable} from "@blg/blg-core";

export default class MafiNotation extends Parseable {
    public text!: string;
    public notation!: string;
    public polygon?: string;
    public parsedPolygon?: MafiLocationCoordinates[];
    public type!: "AREA" | "ZONE" | "BLOCK" | "ROW";
    public position!: MafiLocationCoordinates;
    public children?: MafiNotation[];

    public static parseFromObject(object: any): MafiNotation {
        const notation = new MafiNotation();
        Object.assign(notation, object);

        if (notation.type === "ROW") {
            notation.children = undefined;
        }
        const splitted = notation.notation.split("-");
        notation.text = splitted[splitted.length - 1];

        if (notation.polygon) {
            const splittedPolygon = notation.polygon.split(";");
            notation.parsedPolygon = splittedPolygon.map(polygon => {
                const splittedPolygonCoordinates = polygon.split(",");
                return {
                    lat: Number(splittedPolygonCoordinates[0]),
                    long: Number(splittedPolygonCoordinates[1])
                }
            });
        }

        return notation;
    }
}

export interface MafiLocationCoordinates {
    lat: number,
    long: number
}
