import React, {useEffect, useState} from "react";
import {Backdrop, Box, Typography} from "@mui/material";
import LoadingImage from "../assets/images/loading-animation.svg";
import {useAppSelector} from "../store/hooks";

const LoadingComponent = () => {
    const globalStore = useAppSelector(state => state.global);
    const showLoading = globalStore.showLoading;
    const loadingText = globalStore.loadingText;
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);

    useEffect(() => {
        setShowLoadingAnimation(showLoading);
    }, [showLoading]);

    return (
        <Box sx={{position: "absolute", top: 0, left: 0}}>
            <Backdrop
                sx={{
                    backgroundColor: "rgba(222,224,233,0.9)",
                    color: '#fff',
                    zIndex: 10000,
                    display: "flex", flexDirection: "column"
                }}
                open={showLoadingAnimation}>
                <object type="image/svg+xml" style={{width: 275, height: 275}} data={LoadingImage}>svg-animation
                </object>
                {loadingText &&
                    <Typography variant={"h6"}>
                        {loadingText}
                    </Typography>
                }
            </Backdrop>
        </Box>
    )
};

export default LoadingComponent;
