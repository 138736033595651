import React from "react";
import {StackDetailComponent} from '@blg/blg-core'
import {setLastSavedLocation} from "../../store/slices/custom";
import {setActiveView, setShowLoading} from "../../store/slices/global";
import {store} from "../../store/store";
import { useAppSelector } from "store/hooks";

const StackDetailView = () => {
    const lastSavedLocation = useAppSelector(state => state.custom).lastSavedLocation;
    const latitude = useAppSelector(state => state.custom).latitude;
    const longitude = useAppSelector(state => state.custom).longitude;

    return <StackDetailComponent
        lastSavedLocation={lastSavedLocation}
        latitude={latitude}
        longitude={longitude}
        updateLastSavedLocation={(location) => store.dispatch(setLastSavedLocation(location!.notation))}
        setShowLoading={(loading) => store.dispatch(setShowLoading(loading))}
        setActiveView={(activeView) => store.dispatch(setActiveView(activeView))}/>
}

export default StackDetailView;
