import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Zoom from 'react-medium-image-zoom'
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect, useState} from "react";
import {Box, Button, DialogActions, DialogContent} from "@mui/material";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

import LoginTutorial from "../../assets/images/tutorial/LOGIN.jpg";
import StackTutorial from "../../assets/images/tutorial/STACK.jpg";
import NewStackTutorial from "../../assets/images/tutorial/NEW_STACK.jpg";
import AddMafiTutorial from "../../assets/images/tutorial/ADD_MAFI.jpg";
import UnstackTutorial from "../../assets/images/tutorial/UNSTACK.jpg";
import UnstackDetailsTutorial from "../../assets/images/tutorial/UNSTACK_DETAILS.jpg";

import {useAppSelector} from "../../store/hooks";
import {Tutorial} from "../../store/slices/global";

const defaultStyle = {
    tutorialImg: {
        width: '100%',
        height: "90vH"
    }
}

export interface TutorialDialogProps {
    showDialog: boolean,
    closeEvent(): any,
}

const TutorialDialogComponent = (props: TutorialDialogProps) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);

    const activeTutorial = useAppSelector(state => state.global).activeTutorial;

    const [navTutorial, setNavTutorial] = useState<{  tutorial: Tutorial, show: boolean  }>({tutorial: "LOGIN", show: false});

    useEffect(() => {
        setNavTutorial(activeTutorial);
    }, [activeTutorial]);

    useEffect(() => {
        setOpen(props.showDialog);
    }, [props.showDialog])

    const getImg = () => {
        switch (navTutorial.tutorial) {
            case "LOGIN":
                return <img style={defaultStyle.tutorialImg} src={LoginTutorial}/>
            case "STACK":
                return <img style={defaultStyle.tutorialImg} src={StackTutorial}/>
            case "ADD_MAFI":
                return <img style={defaultStyle.tutorialImg} src={AddMafiTutorial}/>
            case "NEW_STACK":
                return <img style={defaultStyle.tutorialImg} src={NewStackTutorial}/>
            case "UNSTACK":
                return <img style={defaultStyle.tutorialImg} src={UnstackTutorial}/>
            case "UNSTACK_DETAILS":
                return <img style={defaultStyle.tutorialImg} src={UnstackDetailsTutorial}/>
        }
    };

    return (
        <Box>
            <Dialog
                onClose={() => props.closeEvent()}
                fullWidth
                maxWidth={"xl"}
                open={open}
            >
                <DialogTitle sx={{textAlign: "center"}}>
                    Tutorial
                </DialogTitle>
                <DialogContent sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Zoom>
                        {getImg()}
                    </Zoom>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"outlined"}
                        startIcon={<CloseIcon/>}
                        onClick={props.closeEvent}>
                        {t("GENERAL.INFO_DIALOG.CLOSE")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default TutorialDialogComponent;
