import {createSlice, PayloadAction} from "@reduxjs/toolkit"

export interface CustomState {
    lastSavedLocation?: string,
    latitude?: number,
    longitude?: number,
    accuracy?: number,
}

const initialState: CustomState = {
    lastSavedLocation: undefined,
};

const customState = createSlice({
    name: "custom",
    initialState,
    reducers: {
        setLastSavedLocation(state: CustomState, action: PayloadAction<string | undefined>) {
            state.lastSavedLocation = action.payload;
        },
        setPosition(state: CustomState, action: PayloadAction<{ latitude: number, longitude: number, accuracy: number }>) {
            state.latitude = action.payload.latitude;
            state.longitude = action.payload.longitude
            state.accuracy = action.payload.accuracy;
        },
    },
});

export const {setLastSavedLocation, setPosition} = customState.actions;
export default customState.reducer;
