export enum ROUTES {
    ROOT = "/",
    HOME = "/home",
    LOGIN = "/login",

    STACK = 'stack',
    STACK_DETAILS = '/home/stack-details/:mafiNo',

    UNSTACK = 'unstack',
    UNSTACK_DETAILS = '/home/unstack-details/:mafiNo'
}