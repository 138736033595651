import {calcCoordsDistance} from "@blg/blg-core/lib/cjs/misc/roTrail/parseAPIDataHelper";
import {Theme} from "@mui/material/styles";
import {useMediaQuery} from "@mui/system";
import React, {useEffect, useRef, useState} from "react";
import {Emitter, EmitterEvents, RoTrailNotationService, SnackbarUtil, parseNotations} from '@blg/blg-core';
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Outlet, useNavigate} from "react-router-dom";
import ReconnectRFIDDialog from "../components/dialog/reconnectRFIDDialog.component";
import LoadingComponent from "../components/loading.component";
import LocalForageHelper from "../misc/localforageHelper";
import {ROUTES} from "../routes/routes";
import {useAppSelector} from "../store/hooks";
import NavigationComponent from "../components/navigation/navigation.component";
import {setRefreshToken, setToken} from "../store/slices/auth";
import {setPosition} from "../store/slices/custom";
import {store} from "../store/store";
import {setScannerConnection, setShowLoading, setShowLoadingText} from "../store/slices/global";
import {hexToAscii} from "../misc/rfidHelper";
import { RFIDScanResult } from "@blg/blg-core/lib/esm/components/roTrail/stack/rfidTagSelectionDialogComponent";

const HomeView: React.FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const logoutTimer = useRef<NodeJS.Timeout | null>(null);
    const [showConnectionDialog, setShowConnectionDialog] = useState(false);
    const globalStore = useAppSelector(state => state.global);

    const refreshToken = useAppSelector(state => state.auth).refreshToken;
    const driverLatitude = useAppSelector(state => state.custom).latitude;
    const driverLongitude = useAppSelector(state => state.custom).longitude;

    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
    const scannerConnection = globalStore.scannerConnection;
    const rfidConnector = useRef<any>();

    const [watchId, setWatchID] = useState<number | null>(null);
    const [tempLocation, setTempLocation] = useState<GeolocationPosition | null>(null);

    useEffect(() => {
        checkLogin();
        updateNotations();

        if (window.cordova && window.cordova.plugins && isSmallScreen) {
            if (!scannerConnection) {
                store.dispatch(setShowLoading(true));
                store.dispatch(setShowLoadingText("Verbindung wird hergestellt..."));
            }

            rfidConnector.current = window.cordova.plugins.RFID;
            rfidConnector.current.init(initCallback, initErrorCallback);
        }

        Emitter.on(EmitterEvents.TOKEN_EXPIRED, () => {
            SnackbarUtil.error(t("ERROR.SESSION_EXPIRED"));
            navigate(ROUTES.LOGIN, { replace: true });
        });

        Emitter.on(EmitterEvents.TOKEN_REFRESHED, (payload: { accessToken: string, refreshToken: string }) => {
            store.dispatch(setRefreshToken(payload.refreshToken));
            store.dispatch(setToken(payload.accessToken));
        });

        if (window.api) {
            window.api.getLocation();
            window.api.receiveLocation((data: { latitude: number, longitude: number }) => {
                console.log('received data', data, data.latitude);
                if (data.latitude && data.longitude) {
                    setTempLocation({
                        coords: {
                            altitudeAccuracy: 0,
                            accuracy: 1,
                            altitude: 0,
                            heading: 0,
                            speed: 1,
                            latitude: data.latitude,
                            longitude: data.longitude
                        },
                        timestamp: 0
                    })
                }
            });
        } else {
            // Watch the driver position and save it in the store
            const id = navigator.geolocation.watchPosition((position) => {
                    if (position.coords.latitude && position.coords.longitude) {
                        setTempLocation(position);
                    }
                },
                (error) => {
                    console.log(error);
                }, {enableHighAccuracy: true, maximumAge: 5000});

            setWatchID(id);
        }

        return () => {
            if (watchId) {
                navigator.geolocation.clearWatch(watchId);
            }

            if (rfidConnector.current) {
                rfidConnector.current.disconnect();
                store.dispatch(setScannerConnection(false));
            }
            Emitter.removeAllListeners();
        }
    }, []);

    useEffect(() => {
        if (tempLocation) {
            checkLocation(tempLocation);
        }
    }, [tempLocation]);

    const checkLocation = (position: GeolocationPosition) => {
        if (driverLongitude && driverLatitude) {
            const distance = calcCoordsDistance({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                },
                {latitude: driverLatitude, longitude: driverLongitude});
            // Only update the location if the distance is bigger than x meters
            if (distance > 5) {
                store.dispatch(setPosition({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        accuracy: position.coords.accuracy
                    }
                ));
            }
        } else if (position.coords.longitude && position.coords.latitude) {
            store.dispatch(setPosition({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    accuracy: position.coords.accuracy
                }
            ));
        }
    }

    const initCallback = (message: string) => {
        store.dispatch(setShowLoading(false));
        store.dispatch(setShowLoadingText(undefined));
        console.log('initCallback', message);

        if (message.includes("404")) {
            SnackbarUtil.error(t("ERROR.NO_SCANNER"));
            return;
        } else if (message.includes("failed") || message.includes("Failed")) {
            initErrorCallback(message);
            return;
        } else if (message.includes("Disconnected")) {
            store.dispatch(setShowLoading(true));
            store.dispatch(setShowLoadingText("Verbindung wird erneut hergestellt..."));
            return;
        } else if (message.includes("Connected to")) {
            store.dispatch(setShowLoadingText(undefined));
            store.dispatch(setShowLoading(false));
        }

        store.dispatch(setScannerConnection(true));
        setShowConnectionDialog(false);
        rfidConnector.current.listenInventory(tagResultCallback, tagErrorCallback);
    };

    const connectToReader = () => {
        if (!scannerConnection) {
            store.dispatch(setShowLoading(true));
            store.dispatch(setShowLoadingText("Verbindung wird hergestellt..."));
        }

        rfidConnector.current.connect();
    }

    const initErrorCallback = (message: string) => {
        store.dispatch(setScannerConnection(false));
        if (!message.includes("null")) {
            SnackbarUtil.warning(message);
        }
        console.log('initErrorCallback', message);
        setShowConnectionDialog(true);
    };

    const tagResultCallback = (message: string) => {
        console.log("tagResultCallback", message);
        const json: Array<RFIDScanResult> = JSON.parse(message);
        const updatedJSON: Array<RFIDScanResult> = json.map(tag => {
            const decoded = hexToAscii(tag.id);
            return {
                id: tag.id,
                parsedId: decoded,
                rssi: tag.rssi,
                // @ts-ignore
                pcValue: (tag.pcValue as number).toString(16)
            }
        })
        console.log('updated json stuff', updatedJSON);
        Emitter.emit("TAGS", updatedJSON);
    };

    const tagErrorCallback = (message: string) => {
        SnackbarUtil.warning(message);
        console.log('tagErrorCallback', message)
    }

    const updateNotations = async () => {
        try {
            // Sync all the notations after the login
            const notations = await RoTrailNotationService.instance.getAllNotations();
            await LocalForageHelper.instance.setAllMafiNotations(notations);
            const result = parseNotations(notations);

            await LocalForageHelper.instance.setParsedNotations(result);
        } catch (e) {

        }
    }

    const checkLogin = () => {
        logoutTimer.current = setTimeout(() => {
            const tokenIsValid = validateToken(refreshToken);
            if (!tokenIsValid) {
                goToLogin();
            } else {
                checkLogin();
            }
        }, 60000);
    }

    const goToLogin = () => {
        localStorage.removeItem(`persist:${process.env.REACT_APP_STORE_KEY}`);
        store.dispatch(setShowLoading(false));
        navigate(ROUTES.LOGIN);
    };

    /**
     * Check if the token is valid
     * @param token
     */
    const validateToken = (token?: string): boolean => {
        if (!token) {
            return false;
        }

        const arrayToken = token.split('.');
        const tokenPayload = JSON.parse(atob(arrayToken[1]));
        return !(new Date().getTime() >= (new Date(tokenPayload?.exp * 1000).getTime()));
    }

    return (
        <Box sx={{display: "flex", height: "100vh", width: "100%"}}>
            <LoadingComponent />
            <Box sx={{display: "flex", flexDirection: "column", width: "100%", overflowY: "hidden"}}>
                <NavigationComponent/>
                <Box sx={{
                    backgroundColor: "#D1E0F9",
                    height: "100%",
                    width: "100%",
                    boxSizing: "border-box",
                    maxHeight: "calc(100vh - 56px)"
                }}>
                    <Outlet/>
                </Box>
            </Box>
            <ReconnectRFIDDialog showDialog={showConnectionDialog} reconnectEvent={() => {
                setTimeout(() => {
                    connectToReader();
                }, 500);
                setShowConnectionDialog(false);
            }} closeEvent={() => {
                setShowConnectionDialog(false);
            }}/>
        </Box>
    );
};

export default HomeView;
