import React, {ChangeEvent, useEffect, useState} from 'react';
import {Typography, Box, Backdrop, Button} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import TutorialDialogComponent from "../components/dialog/tutorialDialog.component";
import {setActiveTutorial} from "../store/slices/global";
import {store} from "../store/store";
import {setRefreshToken, setToken, setUsername} from "../store/slices/auth";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../routes/routes";
import LoadingImage from "../assets/images/loading-animation.svg";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {defaultStyles} from "../styles/defaultStyles";
import { SnackbarUtil } from '@blg/blg-core';
import HelpIcon from "@mui/icons-material/Help";
import AuthService from '@blg/blg-core/lib/esm/services/roTrail/authService';

function Login() {
    const theme = useTheme();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);

    let form: ValidatorForm;
    const [formInvalid, setFormInvalid] = useState(true);
    const [formData, setFormData] = useState<{userId: string, password: string}>({
        userId: '', password: ''
    });
    const [showTutorial, setShowTutorial] = useState(false);

    useEffect(() => {
        store.dispatch(setActiveTutorial({ tutorial: "LOGIN", show: true }));

        if(process.env.REACT_APP_ENV === 'LOCAL') {
            setFormData({
                userId: process.env.REACT_APP_BLG_LOGIN_USER!,
                password: process.env.REACT_APP_BLG_LOGIN_PASSWORD!
            });
            setFormInvalid(false);
        }
    }, []);

    useEffect(() => {
        checkForm();
    }, [formData.userId, formData.password]);

    async function submitForm() {
        try {
            setShowLoadingAnimation(true);

            const authResponse = await AuthService.instance.login({
                username: formData.userId.trim(),
                password: formData.password,
                grant_type: "password"
            });

            if (authResponse.data.access_token) {
                const accessToken = authResponse.data.access_token;
                const refreshToken = authResponse.data.refresh_token;

                store.dispatch(setToken(accessToken));
                store.dispatch(setRefreshToken(refreshToken));
                store.dispatch(setUsername(formData.userId));

                // Wait for the token to be set
                setTimeout(async () => {
                    setShowLoadingAnimation(false);
                    navigate(ROUTES.HOME);
                }, 200);
            }
        } catch (e) {
            SnackbarUtil.error("LOGIN_VIEW.ERROR");
            setShowLoadingAnimation(false);
        }
    }

    /**
     * shows loading animation during login
     */
    useEffect(() => {
        setShowLoadingAnimation(showLoadingAnimation);
    }, [showLoadingAnimation]);

    async function checkForm() {
        const isFormValid = await form.isFormValid(true);
        setFormInvalid(!isFormValid);
    }

    async function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const key: string = event.target.name;

        setFormData(prevState => {
            return {
                ...prevState,
                [key]: event.target.value
            };
        });
        await checkForm();
    }

    return (
        <Box sx={{
            backgroundColor: theme.palette.primary.main, height: "100vh", width: "100%",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
        }}>
            <Typography sx={{fontWeight: 'bold', color: theme.palette.white.main, mb: 9, fontSize: { sm: "60px", xs: '35px' }}}>
                {t("LOGIN_VIEW.TITLE")}
            </Typography>
            <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
                <Box sx={{width: "90%", maxWidth: "400px", p: 2}} style={defaultStyles.contentBox}>
                    <Typography variant="h6" sx={{mb: 1.5, textAlign: "center"}}>
                        {t("LOGIN_VIEW.SUBTITLE")}
                    </Typography>
                    <ValidatorForm autoComplete="off" onSubmit={submitForm} ref={node => (form = node!)}>
                        <TextValidator
                            sx={{mb: 2}}
                            fullWidth
                            label={t("LOGIN_VIEW.USERID")}
                            onChange={handleChange}
                            id="userId"
                            name="userId"
                            value={formData.userId}
                            validators={["required", "minStringLength:3"]}
                            errorMessages={[t("ERROR.REQUIRED"), t("ERROR.MIN_LENGTH", {min: 3})]}
                            inputProps={{"data-testid": "login-userId"}}
                        />
                        <TextValidator
                            sx={{mb: 2}}
                            fullWidth
                            type="password"
                            label={t("LOGIN_VIEW.PASSWORD")}
                            onChange={handleChange}
                            id="password"
                            name="password"
                            value={formData.password}
                            validators={["required", "minStringLength:3"]}
                            errorMessages={[t("ERROR.REQUIRED"), t("ERROR.MIN_LENGTH", {min: 3})]}
                            inputProps={{"data-testid": "login-password"}}
                        />
                        <Button
                            sx={{marginTop: "30px"}}
                            type="submit"
                            disabled={formInvalid}
                            variant="contained"
                            color="primary"
                            size="large"
                            data-testid="login-submit"
                        >{t("GENERAL.NEXT")}</Button>
                    </ValidatorForm>
                </Box>
                <Box sx={{position: "relative"}}>
                    <Box  sx={{display: "flex", alignItems: "center", position: "absolute", bottom: 10, right: -10}}>
                        <HelpIcon onClick={() => {
                            setShowTutorial(true);
                        }}  sx={{color: theme.palette.primary.main, mr: 1, pointer: "cursor"}}/>
                        <Typography sx={{opacity: 0.5, width: 80}}
                                    variant={"caption"}>Version: {process.env.REACT_APP_VERSION}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{position: "absolute", top: 0, left: 0}}>
                <Backdrop
                    sx={{backgroundColor: "rgba(222,224,233,0.9)", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={showLoadingAnimation}
                >
                    <object type="image/svg+xml" style={{width: 275, height: 275}} data={LoadingImage}>svg-animation
                    </object>
                </Backdrop>
            </Box>
            <TutorialDialogComponent showDialog={showTutorial} closeEvent={() => setShowTutorial(false)}/>
        </Box>
    );
}

export default Login;
